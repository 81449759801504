<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Registrar Pago</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <div class="mb-3 text-body-1 black--text" v-if="isBookSelected">
                <v-icon color="green" large>
                  mdi-book-check
                </v-icon>
                El libro para este curso esta seleccionado
              </div>
              <div class="mb-3 text-body-1 black--text" v-else>
                <v-icon color="red" large>
                  mdi-book-off-outline
                </v-icon>
                El libro para este curso <b>NO</b> esta seleccionado
              </div>
              <span class="text-h6 black--text">
                Total a pagar: <b>${{ total }}</b>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                label="Número de recibo"
                required
                outlined
                hide-details
                v-model="ticket"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                ref="paymentDateMenu"
                v-model="paymentDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="paymentDate"
                    label="Fecha en la que se realizó el pago"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    required
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="paymentDate"
                  no-title
                  scrollable
                  :max="new Date().toISOString().slice(0, 10)"
                  @input="paymentDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="add()" :loading="isLoading">
          Registrar Pago
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddCourse",
  data() {
    return {
      dialog: false,
      isLoading: false,
      ticket: null,
      paymentDateMenu: false,
      paymentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  props: {
    id_student: Number,
    id_book: Number,
    courses: {
      type: Array,
      required: true,
    },
    total: String,
    clean: { type: Function },
    isBookSelected: Boolean,
  },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionamiento
    add: function() {
      let formData = new FormData();
      formData.append("id_student", this.id_student);
      formData.append("courses", this.courses);
      // formData.append('courses', `[${this.courses}]`);
      formData.append("id_book", this.id_book);
      formData.append("ticket", this.ticket);
      formData.append("paymentDate", this.paymentDate);
      // for (var value of formData.values()) {
      //   console.log(value);
      // }
      this.isLoading = true;
      this.$http
        .post(this.api + "pay/oldCashv3", formData)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          // setTimeout(function() {
          //   console.log('aver');
          //   window.location.reload();
          // }, 1000);
          if (data.response) {
            this.changeModal();
            this.clean();
            this.ticket = "";
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    changeModal: function() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
};
</script>

<style></style>
