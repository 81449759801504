<template>
  <div>
    <v-banner>
      <div class="pt-1 d-flex align-center">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Caja Registradora
        </p>
        <v-autocomplete
          class="mr-5 ml-5"
          :label="isLoadingStudents ? 'Cargando alumnos...' : 'Buscar alumno...'"
          :loading="isLoadingStudents"
          :items="students"
          item-text="code_custom"
          item-value="id_student"
          :disabled="isLoadingStudents"
          required
          clearable
          outlined
          hide-details
          @keyup.enter="getCourses"
          v-model="select_id_student"
        >
        </v-autocomplete>
      </div>
    </v-banner>

    <div v-if="searchInArray(actions, 'Ver')">
      <v-container v-if="student.id_student">
        <v-row>
          <v-col>
            <div class="content-cash">
              <v-card elevation="1">
                <v-card-title>Información de Compra</v-card-title>
                <v-card-text class="text--primary">
                  <v-row>
                    <v-col cols="12" sm="12" md="4">
                      <p class="body-1 mb-0">
                        <span class="font-weight-bold"
                          >Nombre del Alumno:
                        </span>
                        <br />
                        <span>
                          {{ student.first_name }} {{ student.last_name }}
                        </span>
                      </p>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <p class="body-1 mb-0">
                        <span class="font-weight-bold">
                          Código del Alumno:
                        </span>
                        <br />
                        <span> {{ student.code }} </span>
                      </p>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <p class="body-1 mb-0">
                        <span class="font-weight-bold">Curso Actual: </span>
                        <br />
                        <span v-if="current_course.course == undefined">
                          Sin curso (Nuevo Ingreso)
                        </span>
                        <span v-else>
                          {{ current_course.program }}
                          {{ current_course.course }}
                        </span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-card elevation="0" :loading="loadStudent">
                    <v-row>
                      <v-col cols="12" sm="12" class="mx-auto">
                        <v-alert
                          v-if="Object.keys(items).length === 0"
                          type="info"
                          border="left"
                        >
                          <span class="title">NO posee pagos pendientes</span>
                        </v-alert>
                        <v-data-table
                          v-else
                          v-model="payment.selectedItems"
                          :headers="headers"
                          :items="items"
                          item-key="course"
                          show-select
                          hide-default-footer
                          class="elevation-0"
                        >
                          <template v-slot:[`item.data-table-select`]="{ isSelected, select, item }">
                            <v-checkbox :input-value="('id_course' in item) ? isSelected : true" @change="select($event);" :disabled="('id_course' in item) ? !canBeChecked(item) : true"></v-checkbox>
                          </template>
                          <!-- Custom items -->
                          <template v-slot:[`item.course`]="{ item }">
                            {{
                              (item.program =
                                item.program != undefined
                                  ? item.program
                                  : (item.program = 'No posee curso a pagar'))
                            }}
                            <span v-if="item.program != undefined">
                              {{ item.course }} - {{ item.cycle }}
                            </span>
                            <br />
                            <span
                              class="font-weight-bold"
                              v-if="
                                item.colaborator_discount == 1 &&
                                  item.course != undefined
                              "
                            >
                              Posee un descuento del
                              {{ item.percent_discount }}%
                            </span>
                          </template>
                          <template v-slot:[`item.course_price`]="{ item }">
                            <span
                              class="text-decoration-line-through mr-2"
                              v-if="
                                item.colaborator_discount == 1 &&
                                  item.course != undefined
                              "
                            >
                              $
                              {{ parseFloat(item.course_price).toFixed(2) }}
                            </span>
                            <span v-else>
                              <span v-if="item.course != undefined">
                                ${{ parseFloat(item.course_price).toFixed(2) }}
                              </span>
                              <span v-else>$0.00</span>
                            </span>
                            <span
                              v-if="
                                item.colaborator_discount == 1 &&
                                  item.course != undefined
                              "
                            >
                              $
                              {{ parseFloat(total_discount).toFixed(2) }}
                            </span>
                          </template>
                          <template v-slot:[`item.book`]="{ item }">
                            <tr>
                              <td>
                                <v-row v-if="lastCourse == item.course">
                                  <!-- Información del libro -->
                                  <v-col
                                    cols="12"
                                    class="d-flex flex-column align-center justify-center text-center pb-0"
                                  >
                                    <!-- Valdidación de texto, si el libro es obligatorio o no para ese libro -->
                                    <span
                                      v-if="item.book_required == 1"
                                      class="black-text font-weight-bold body-1"
                                    >
                                      El libro para el curso <br />
                                      es obligatorio
                                    </span>
                                    <span
                                      v-else
                                      class="black-text font-weight-bold body-1"
                                    >
                                      El libro para este curso <br />
                                      <span class="red pa-1 white--text"
                                        >NO</span
                                      >
                                      es obligatorio
                                    </span>
                                  </v-col>
                                  <!-- Imagen del libro -->
                                  <v-col
                                    cols="12"
                                    class="d-flex align-center justify-center p-1"
                                  >
                                    <!-- Input de selección si desea el libro o no -->
                                    <v-switch
                                      v-model="swbook"
                                      inset
                                      :label="swbook ? 'Quitar libro' : 'Agregar libro'"
                                      @change="changeBookStatus"
                                    ></v-switch>
                                    <div class="d-flex flex-column align-center justify-center">
                                      <v-img
                                        v-if="item.image"
                                        style="cursor: pointer; max-width: 62px; max-heigth: 82px; margin-left: 4px;"
                                        width="100"
                                        :src="img + 'book/' + item.image"
                                        @click="
                                          zoomImage(
                                            img + 'book/' + item.image,
                                            item.book
                                          )
                                        "
                                      ></v-img>
                                      <span>{{ item.book }}</span>
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row v-else>
                                  <span
                                    class="black-text font-weight-bold body-1"
                                  >
                                    El libro para cursos pasados <br />
                                    <span class="red pa-1 white--text">NO</span>
                                    es necesario comprarlo
                                  </span>
                                </v-row>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:[`item.book_price`]="{ item }">
                            <tr>
                              <td>
                                <v-row v-if="lastCourse == item.course">
                                  <v-col>
                                    <span
                                      class="black-text font-weight-bold body-1"
                                    >
                                      ${{
                                        parseFloat(item.book_price).toFixed(2)
                                      }}
                                    </span>
                                  </v-col>
                                </v-row>
                                <v-row v-else>
                                  <v-col>
                                    <span
                                      class="black-text font-weight-bold body-1"
                                    >
                                      Ninguno
                                    </span>
                                  </v-col>
                                </v-row>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div
                          v-if="items.length >= 2"
                          class="d-flex justify-center my-6"
                        >
                          <v-alert outlined dense type="info" border="left">
                            <p class="mb-0">
                              El alumno posee pagos <b>pendientes</b>. Para
                              poder realizar el pago de el último curso debe pagar antes los cursos pendientes.
                              NO es obligatorio pagar todo de un solo, es decir, el pago de los cursos se puede realizar gradualmente.
                            </p>
                          </v-alert>
                        </div>
                        <v-divider></v-divider>
                        <p class="text-center mb-0 mt-10">
                          <v-btn
                            class="mr-2"
                            color="secondary"
                            @click="cleanPayment()"
                          >
                            <v-icon left>mdi-close</v-icon>
                            Cancelar
                          </v-btn>
                          <v-btn color="primary" @click="checkItemsToPay()">
                            <v-icon left>mdi-credit-card-outline</v-icon>
                            Realizar Pago
                          </v-btn>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col cols="12" sm="12" md="4" offset-md="4">
            <h4 class="text-center black--text">
              Busque un alumno para obtener su información de pago.
              <br />
              <v-icon x-large color="primary">
                mdi-magnify
              </v-icon>
            </h4>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <RegisterPayment
      ref="mdlRegisterPayment"
      :id_student="select_id_student"
      :id_book="select_id_book"
      :courses="courses"
      :total="totalPay"
      :clean="cleanPayment"
      :isBookSelected="swbook"
    ></RegisterPayment>

    <v-dialog v-model="dialogZoomBook" max-width="400px">
      <v-card>
        <v-card-title class="tex-center">
          <v-icon color="primary" left>mdi-eye</v-icon>
          {{ selectedImg.book }}
          <v-spacer></v-spacer>
          <v-btn icon small @click="dialogZoomBook = false">
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="m-0 p-0">
          <v-img v-if="selectedImg" max-width="400px" :src="selectedImg.url" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RegisterPayment from './RegisterPayment';
export default {
  name: 'CashRegister',
  components: { RegisterPayment },
  data: () => ({
    dialogZoomBook: false,
    dialogConfirm: false,
    select_id_student: null,
    select_id_book: null,
    select_id_course: null,
    itemsSelected: [],
    payment: {
      selectedItems: [],
    },
    items: [],
    headers: [
      {
        text: 'Concepto del Curso',
        value: 'course',
        sortable: false,
        class: 'text-uppercase black--text body-1 font-weight-bold',
      },
      {
        text: 'Costo del Curso',
        value: 'course_price',
        sortable: false,
        class: 'text-uppercase black--text body-1 font-weight-bold',
      },
      {
        text: 'Concepto del Libro',
        value: 'book',
        sortable: false,
        class: 'text-uppercase black--text body-1 font-weight-bold',
      },
      {
        text: 'Costo del Libro',
        value: 'book_price',
        sortable: false,
        class: 'text-uppercase black--text body-1 font-weight-bold',
      },
    ],
    students: [],
    student: {},
    current_course: [],
    courses: [],
    pagos: [],
    checkCourseUndefined: false,
    book: 'Quitar libro',
    swbook: true,
    bookPrice: 0,
    bookId: null,
    pay: {
      amount: '0',
    },
    response: 0,
    discount: 0,
    total_discount: 0,
    percentage: 20,
    selectedImg: {
      url: null,
      book: '',
    },
    mdlTicket: false,
    loadStudent: false,
    isLoadingStudents: false,
  }),
  mounted() {
    this.getCurrentActions('Caja Registradora');
    this.getStudents();
  },
  methods: {
    ...mapActions({
      getCurrentActions: 'getCurrentActions',
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    getStudents: async function() {
      this.isLoadingStudents = true;
      await this.$http.get(this.api + 'student/all-active').then(({ data }) => {
        data.forEach((item) => {
          item.code_custom =
            item.code + ' - ' + item.first_name + ' - ' + item.last_name;
        });
        this.students = data;
      }).finally(() => {
        this.isLoadingStudents = false;
      });
    },

    getCourses: async function() {
      this.pagos = [];
      this.discount = 0;
      this.itemsSelected = []; // limpia arreglo de items seleccionados
      this.payment.selectedItems = [];
      this.loadStudent = true;
      this.swbook = true;
      this.bookId = null;
      this.bookPrice = 0;
      this.courses = [];
      this.items = [];
      this.total_discount = 0;
      this.pay = {
        amount: 0,
      };
      let id = this.select_id_student;
      if (id != null) {
        let filtered = this.students.filter(
          (student) => student.id_student == id
        );
        this.student = filtered[0];
        await this.$http
          .get(this.api + 'course_student/actual/' + id)
          .then(({ data }) => {
            this.current_course = data;
            this.getItemsToPay();
          })
          .catch(() => {
            this.openSnackbar({
              type: 'error',
              message: 'No se pudo obtener al estudiante seleccionado',
            });
          })
          .then(() => {
            this.loadStudent = false;
          });
      } else {
        this.openSnackbar({
          type: 'info',
          message: 'El código no ha sido encontrado',
        });
      }
    },

    getItemsToPay: async function() {
      await this.$http
        .get(this.api + 'pay/itemsv3/' + this.select_id_student)
        .then(({ data }) => {
          this.items = data;
          data.forEach((item) => {
            if (item.course == undefined && item.program == undefined) {
              this.checkCourseUndefined = true;
              this.pagos.push(parseFloat(item.book_price));
              this.bookId = item.id_book;
              this.select_id_book = this.bookId;
              this.courses = [];
            }
            // Verifica si tiene descuento y lo aplica
            if (
              item.course_price != undefined &&
              item.colaborator_discount == 1
            ) {
              this.discount = item.course_price * (item.percent_discount / 100);
              this.total_discount = item.course_price - this.discount;
            } else if (
              item.course_price != undefined &&
              item.colaborator_discount == 0
            ) {
              this.pagos.push(parseFloat(data.course_price));
            }

            // Precio del libro del ultimo curso
            if (
              item.course_price != undefined &&
              item.course == this.lastCourse
            ) {
              this.pagos.push(parseFloat(item.book_price));
              this.bookPrice = parseFloat(item.book_price);
              this.bookId = item.id_book;
              this.select_id_book = this.bookId;
            }
          });
        })
        .catch(() => {
          this.openSnackbar({
            type: 'error',
            message: 'Al parecer hubo un error al comunicarse con el servidor',
          });
        })
        .then(() => {});
    },

    checkItemsToPay: function() {
      if (
        this.payment.selectedItems.length == 0 &&
        this.checkCourseUndefined == false
      ) {
        this.$swal({
          title: 'Error',
          text:
            'Debes seleccionar al menos un curso para poder continuar con el pago.',
          icon: 'error',
        });
        return;
      }
      
      if (
        this.checkCourseUndefined == true &&
        this.payment.selectedItems.length == 0
      ) {
        // agregando libro y verifica que no tenga ningun curso pendiente y solo pueda pagar el libro
        if (this.swbook) {
          this.pagos.push(this.bookPrice);
          this.mdlTicket = true;
          this.modalTicket();
        } else {
          this.$swal({
            title: '¡Atención!',
            text: 'Debes seleccionar el libro para poder realizar el pago.',
            icon: 'error',
          });
        }
        return;
      } 
      // Metodo que recorre el array de objetos de los cursos que tiene pendientes el alumno
      this.itemsForEach();
    },

    itemsForEach: function() {
      // limpiando cursos
      this.courses = [];
      this.pagos = [];

      this.sanitizeSelectedItems.forEach((item) => {
        this.courses.push(item.id_course);
        this.pagos.push(item.course_price - this.discount);
        this.mdlTicket = true;
      });
      // agregando libro
      if (this.swbook) {
        this.pagos.push(this.bookPrice);
      }
      // Para poder pagar en caja
      if (this.mdlTicket == true) {
        this.modalTicket();
      }
      this.mdlTicket = false;
    },

    firstAndSecondItems: function() {
      let firstCourse = this.items[0];
      let secondCourse = this.items[1];
      let itemsS = this.itemsSelected;

      if (firstCourse === itemsS[0] && secondCourse === itemsS[1]) {
        return true;
      } else {
        this.alertItemsPay(3);
        return false;
      }
    },

    /**
     * Si el 'value' == 2, es porque tiene 2 cursos pero solo selecciono el último.
     * Si el 'value' == 3, es porque tiene 3 cursos pero solo selecciono el útlimo.
     */
    alertItemsPay: function(value) {
      let textAlert = '';
      value =
        value == 2
          ? (textAlert =
              'Tiene 2 cursos y ha seleccionado el último curso, para poder pagar el último curso, debe pagar el curso anterior o pagar los 2 cursos')
          : (textAlert =
              'Tiene 3 cursos y ha seleccionado un curso, pero posee curso(s) pendiente(s), para poder pagar el último curso, debe pagar los cursos anteriores o pagar los 3 cursos');
      this.$swal({
        title: 'Información',
        text: textAlert,
        icon: 'info',
      });
    },

    changeBookStatus: function() {
      if (!this.swbook) {
        this.book = 'Agregar Libro';
        const index = this.pagos.indexOf(this.bookPrice);
        if (index > -1) {
          this.pagos.splice(index, 1);
        }
        this.select_id_book = null;
      } else {
        this.book = 'Quitar Libro';
        this.select_id_book = this.bookId;
        this.pagos.push(this.bookPrice);
      }
    },

    confirmDialog: function(id) {
      this.id_book_deleted = id;
      this.dialogConfirm = true;
    },
    closeDialog: function() {
      this.dialogConfirm = false;
    },
    modalTicket: function() {
      this.$refs.mdlRegisterPayment.changeModal();
    },
    cleanPayment: function() {
      this.swbook = true;
      this.bookId = null;
      this.bookPrice = 0;
      this.courses = [];
      this.items = [];
      this.itemsSelected = [];
      this.total_discount = 0;
      this.discount = 0;
      this.student = {};
      this.pagos = [];
      this.pay = {
        amount: 0,
      };
      this.payment.selectedItems = [];
      this.current_course = [];
      this.select_id_student = null;
      this.select_id_course = null;
      this.select_id_book = null;
    },
    zoomImage: function(_url, _book) {
      this.dialogZoomBook = true;
      this.selectedImg = { url: _url, book: _book };
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      img: 'getImg',
      actions: 'getCurrentActions',
    }),
    
    totalPay: function() {
      if (this.pagos.length > 0) {
        this.response = this.pagos.reduce((a, b) => a + b);
        this.pay.amount = parseFloat(this.response).toFixed(2);
      } else {
        this.response = parseFloat(0).toFixed(2);
      }
      return parseFloat(this.response).toFixed(2);
    },
    firstCourse() {
      return this.items[0].course;
    },
    lastCourse() {
      return this.items[this.items.length - 1].course;
    },
    lastCourseToPay() {
      return this.items.at(-1);
    },
    canBeChecked() {
      this.sanitizeSelectedItems;
      return (item) => {
        // Find index of the given item from the items array
        const index = this.items.findIndex(element => JSON.stringify(element) === JSON.stringify(item));
        // If its the first one it can be checked
        if (index === 0) {
          return true;
        }
        // Getting the previous item from the items array
        const previous = this.items.at(index - 1);
        // Checking if the previous item is selected
        const isSelectedThePreviousOne = this.payment.selectedItems.some(element => JSON.stringify(element) === JSON.stringify(previous));
        return isSelectedThePreviousOne;
      }
    },
    sanitizeSelectedItems: function() {
      this.payment.selectedItems;
      if (!this.payment.selectedItems.length) {
        this.payment.selectedItems = [];
        return [];
      }

      const sortedSelectedItemsByIndex = this.payment.selectedItems.reduce((prev, curr, index) => {
        // Order is given by the position of the item in the array of items
        const key = this.items.findIndex(item => item.id_course === curr.id_course);

        return {...prev, [key]: curr}
      }, {});

      const sortedIndexOfSelectedItems = Object.keys(sortedSelectedItemsByIndex).sort((a,b) => a - b);

      // First index have to be the index of the first element (0), if not the selection is cleaned
      if (Number(sortedIndexOfSelectedItems[0]) !== 0) {
        this.payment.selectedItems = [];
        return [];
      }

      const result = [];
      
      for (let index = 0; index < sortedIndexOfSelectedItems.length; index++) {
        // The first element is not verified
        if (index == 0) {
          result.push(sortedIndexOfSelectedItems[index]);
          continue;
        }

        const currentValueMinusOne = Number(sortedIndexOfSelectedItems[index] - 1);
        const previousValue = Number(sortedIndexOfSelectedItems[index - 1]);
        // If we substract one to the current value and it is equal to the previous value, it means that they are consecutive
        if (currentValueMinusOne === previousValue) {
          result.push(sortedIndexOfSelectedItems[index]);
        } else {
          break;
        }
      }
      // a new array of selected items is created without the ones that aren't consecutive
      const consecutiveSelectedItems = result.map((index) => this.items[index]);
      
      this.payment.selectedItems = consecutiveSelectedItems;
      return consecutiveSelectedItems;
    },
  },
};
</script>

<style></style>
